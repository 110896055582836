import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "./button";
import logo from "../Assets/Logo/Moto-Doc-Logo.svg";
import logo1 from "../Assets/Logo/MD_EU_LOGO_ORANGE (2).svg";
import logo2 from "../Assets/Logo/MD_EU_LOGO_BLUE.svg";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import "../scss/navbar.css";
import NavItem from "./NavItem";

const Header = () => {
  const [navActive, setNavActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const location = useLocation();

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "FAQ'S", path: "/faq" },
    { name: "Contact Us" },
  ];

  const downloadLinks = [
    { name: "Download Rider App", path: "/rider" },
    { name: "Download Mechanic App", path: "/mechanic" },
  ];

  const handleNavClick = (menu) => {
    if (menu.path) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(menu.path);
    } else if (menu.name === "Contact Us") {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      const section = document.getElementById(menu.name.toLowerCase());
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="header">
      <div className="nav">
        <div>
          <a href="/">
            <img
              src={logo}
              className="logo"
              style={{
                height: 80,
                width: 80,
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt="logo"
            />
          </a>
        </div>
        <div onClick={() => setNavActive(!navActive)} className="navMenuBar">
          {navActive ? (
            <CloseOutlined style={{ fontSize: "30px", color: "#000" }} />
          ) : (
            <MenuOutlined style={{ fontSize: "30px", color: "#000" }} />
          )}
        </div>
        <div className={navActive ? "activeMenu navMenuList" : "navMenuList"}>
          {navLinks.map((menu, idx) => (
            <div
              key={idx}
              className={`navItem ${location.pathname === menu.path ? "navItem activeMenu" : ""}`}
              onClick={() => handleNavClick(menu)}
              style={{ cursor: "pointer" }}
            >
              {menu.name}
            </div>
          ))}

          {/* Conditionally show download links only when navActive is true */}
          {/* {navActive && (
            <>
              {downloadLinks.map((menu, idx) => (
                <div
                  key={idx}
                  className={`navItem ${location.pathname === menu.path ? "navItem activeMenu" : ""}`}
                  onClick={() => handleNavClick(menu)}
                  style={{ cursor: "pointer" }}
                >
                  {menu.name}
                </div>
              ))}
            </>
          )} */}

          {isMobile ? (
            <div className="download">
              <Button
                text="DOWNLOAD APP"
                onClick={() => (document.querySelector(".download-content").style.display = "block")}
              />
              
                <div className="download-content">
                  <div className="dowload-content-din">
                    <div className="download-links">
                      <p>Download App</p>
                      <a href="/rider" target="_blank">
                        <img src={logo1} alt="Moto Doc Logo" />
                        Motodoctor Rider
                      </a>
                      <a href="/mechanic" target="_blank">
                        <img src={logo2} alt="Moto Doc Logo" />
                        Motodoctor Mechanic
                      </a>
                    </div>
                    <div className="video-content">
                      <p>Rider Demo video</p>
                      <br />
                      <iframe
                        src="https://www.youtube.com/embed/q5BoifxfKJo"
                        title="How It Works Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        id="howItWorksVideo"
                      ></iframe>
                      <a href="https://www.youtube.com/embed/q5BoifxfKJo" className="watch-now">
                        &#x25B6; Watch Now
                      </a>
                    </div>
                  </div>
                </div>
            </div>
          ) : (
            <div className="download">
              <Button text="DOWNLOAD APP" />
              <div className="download-content">
                <div className="dowload-content-din">
                  <div className="download-links">
                    <p>Download App</p>
                    <a href="/rider" target="_blank">
                      <img src={logo1} alt="Moto Doc Logo" />
                      Motodoctor Rider
                    </a>
                    <a href="/mechanic" target="_blank">
                      <img src={logo2} alt="Moto Doc Logo" />
                      Motodoctor Mechanic
                    </a>
                  </div>
                  <div className="video-content">
                    <p>Rider Demo video</p>
                    <br />
                    <iframe
                      src="https://www.youtube.com/embed/q5BoifxfKJo"
                      title="How It Works Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      id="howItWorksVideo"
                    ></iframe>
                    <a href="https://www.youtube.com/embed/q5BoifxfKJo" className="watch-now">
                      &#x25B6; Watch Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
