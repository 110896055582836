import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper";
import playStore from "../images/pngegg.png";
import appStore from "../images/clipart1232471.png";

const BoxSlider = () => {
  const features = [
    {
      title: "Raja Durai",
      shortText: "R",
      description:
        "Vera level bro nalla usefull App bro.Na oru time petrol illama road la bike thallitu vanth apo epdi oru app......",
      rating: 5,
      imageUrl: playStore,
      date: "8/7/2024",
    },

    {
      title: "Akhil002839",
      shortText: "A",
      description: "Good service Helped me at right time",
      rating: 4,
      imageUrl: appStore,
      date: "8/8/2024",
    },
    {
      title: "Gayathri",
      shortText: "G",
      description:
        "Ithu mathuri app irukunu enoda vandi puncture agi road la nikumpothu tha therium. Thanks to MotoDoctor company..... ",
      rating: 4,
      imageUrl: playStore,
      date: "8/9/2024",
    },
    {
      title: "Raja Durai",
      shortText: "R",
      description:
        "Vera level bro nalla usefull App bro.Na oru time petrol illama road la bike thallitu vanth apo epdi oru app......",
      rating: 5,
      imageUrl: playStore,
      date: "8/7/2024",
    },

    {
      title: "Akhil002839",
      shortText: "A",
      description: "Good service Helped me at right time",
      rating: 4,
      imageUrl: appStore,
      date: "8/8/2024",
    },
    {
      title: "Gayathri",
      shortText: "G",
      description:
        "Ithu mathuri app irukunu enoda vandi puncture agi road la nikumpothu tha therium. Thanks to MotoDoctor company..... ",
      rating: 4,
      imageUrl: playStore,
      date: "8/9/2024",
    },
  ];

  const renderFeatures = (feature) => (
    <div
      style={{
        width: "93.5%",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        paddingTop: 20,
        position: "relative",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          position: "relative",
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: "#FF8264",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontWeight: 400,
            fontSize: "clamp(14px, 2vw, 17px)", // Responsive font size
          }}
        >
          {feature.shortText}
        </div>

        <div
          style={{
            fontSize: "clamp(12px, 1.5vw, 13px)", // Responsive font size
            fontWeight: 400,
            flex: 1,
          }}
        >
          {feature.title}
        </div>

        {/* Conditionally render stars based on rating */}
        {feature.rating && (
          <div
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: "clamp(14px, 2vw, 17px)", // Responsive font size
              color: "#FFD700",
            }}
          >
            {"⭐".repeat(feature.rating)}
          </div>
        )}
      </div>

      <div
        style={{
          marginTop: 10,
          padding: "0px 30px",
          fontSize: "clamp(12px, 1.5vw, 16px)", // Responsive font size
        }}
      >
        {feature.description}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginTop: 20,
          position: "absolute",
          bottom: 10,
          left: 10,
          right: 10,
        }}
      >
        {/* App image */}
        <img
          src={feature.imageUrl}
          alt="App"
          style={{
            width: 40,
            height: 40,
            objectFit: "cover",
          }}
        />

        {/* Date rectangle */}
        <div
          style={{
            padding: "5px 10px",
            backgroundColor: "#ddd",
            color: "#000",
            borderRadius: "5px",
            fontSize: "clamp(14px, 2vw, 17px)", // Responsive font size
            fontWeight: 500,
          }}
        >
          {feature.date}
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 25,
        }}
      >
        <div
          style={{
            color: "#000",
            fontWeight: 700,
            textAlign: "center",
          }}
          className="content6Head"
        >
          What our riders say about us
        </div>
        <hr
          style={{
            width: "5%",
            border: "1.5px solid #FF8264",
          }}
        />
      </div>
      <div
        style={{
          marginTop: 50,
          width: "90vw",
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          gap: 25,
        }}
      >
        <Swiper
          centeredSlides={true} // Try setting to true for better centering
          spaceBetween={20}
          modules={[Navigation, Autoplay]}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={true}
          grabCursor={true}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          style={{
            padding: "20px 20px",
            "--swiper-navigation-color": "#000",
            "--swiper-navigation-size": "18px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {features.map((feature, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 400,
                  height: 250,
                  backgroundColor: "#FAFAFA",
                  borderRadius: 10,
                  border: "1px solid #FFE5DE", // Adjusted border thickness for better visibility
                  padding: "10px", // Adjust padding to ensure content alignment
                  boxSizing: "border-box", // Includes padding and border in width and height
                }}
              >
                {renderFeatures(feature, index + 1)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BoxSlider;
