import React from "react";
import logo from "../Assets/Logo/Moto-Doc-Logo.svg";
const TermsCondition = () => {
  return (
    <div className="privacy-policy-container">
      <div>
        <a href="/">
          <img
            src={logo}
            className="logo"
            style={{
              height: 80,
              width: 80,
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt="logo"
          />
        </a>
      </div>
      <h1>Terms & Condition</h1>
      {/* Your content goes here */}
      <div class="container">
        <p>
          This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as
          applicable and the amended provisions pertaining to electronic records in various statutes as amended by the
          Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1)
          of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
          regulations, privacy policy and Terms of Use for access or usage of&nbsp;
          <a href="https://www.motodoctor.in/">www.motodoctor.in</a>&nbsp;website and MotoDoctor application for mobile
          and handheld devices which is owned by MotoDoctor.
          <br />
        </p>
        <p>
          <strong>TERMS OF USE</strong>
        </p>
        <ol>
          <li>
            These terms of use (the "Terms of Use") govern your use of our website&nbsp;
            <a href="https://www.motodoctor.in/">motodoctor.in</a> (the "Website") and our "MotoDoctor Rider" &amp;
            &ldquo;MotoDoctor&rdquo; application for mobile and handheld devices (the "App") which is owned by
            MotoDoctor Private limited. The Website and the App are jointly referred to as the "Platform". Please read
            these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may
            not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or
            even merely using the Platform, you shall be contracting with MotoDoctor and you signify your acceptance to
            this Terms of Use and other MotoDoctor policies (including but not limited to the Cancellation &amp; Refund
            Policy, Privacy Policy and Take Down Policy) as posted on the Platform and amended from time to time, which
            takes effect on the date on which you download, install or use the Platform, and create a legally binding
            arrangement to abide by the same.
          </li>
          <li>
            The Platform is owned and operated by MotoDoctor Private Limited, a private limited company incorporated
            under the Companies Act, 1956 and having its registered office at MotoDoctor Private Limited is at D no
            354A,1st floor, MRP Complex, Thider Nagar, Cauvery Nagar, Kulathur(Taluk), Pudukkottai, Tamil Nadu, India.
            For the purpose of these Terms of Use, wherever the context so requires, "you", &ldquo;user&rdquo;, or
            &ldquo;User&rdquo; shall mean any natural or legal person who shall transaction on the Platform by providing
            registration data while registering on the Platform as a registered user using any computer systems. The
            terms "MotoDoctor", "we", "us" or "our" shall mean MotoDoctor Private Limited.
          </li>
          <li>
            MotoDoctor enables transactions on its Platform between participating&nbsp;Mechanic and Customers, dealing
            in (a) automobile repair services (b) motomobile parts, and (c) other products and services ("Platform
            Services"). The Customer can choose and &nbsp;request repair services("Services") from a variety of services
            listed and offered for sale/services by various Mechanic ("Mechanics/s"), on the Platform.
          </li>
          <li>
            For Platform Services, MotoDoctor is merely acting as an intermediary between the Mechanics and Customer
            and/or Customer/Mechanics.
          </li>
          <li>
            For the pickup of vehicle, Mechanic may charge the users of the Platform (Customer), a service fee
            (inclusive of applicable taxes whenever not expressly mentioned) determined on the basis of various factors
            including but not limited to distance covered, time taken, demand for delivery services/Tasks, real time
            analysis of traffic and weather conditions, seasonal peaks or such other parameters as may be determined
            from time to time.
          </li>
          <li>
            Motodoctor has a threshold limit, and upon reaching it, notifications will be sent. If ignored by the
            mechanic, the account may be temporarily suspended until outstanding payments are cleared. Motodoctor
            reserves the right to initiate the necessary processes in such cases.
          </li>
          <li>
            Upon accepting a repair and service request from MotoDoctor users, if a mechanic cancels it three times
            without valid justification, the user's account will be temporarily suspended. MotoDoctor will initiate an
            investigation, and the mechanic must provide a valid reason for the cancellations to lift the suspension.
          </li>
        </ol>
        <p>
          <strong>Amendments</strong>
        </p>
        <p>
          These Terms of Use are subject to modifications. We reserve the right to modify or change these Terms of Use
          and other MotoDoctor policies at any time by posting modified documents on the Platform and notifying you of
          the same for your perusal. You shall be liable to update yourself of such changes, if any, by accessing the
          same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other Moto
          Doctor policies and note the changes made on the Platform. Your continued usage of the Services after any
          change is posted constitutes your acceptance of the amended Terms of Use and other MotoDoctor policies. As
          long as you comply with these Terms of Use, MotoDoctor grants you a personal, non-exclusive, non-transferable,
          limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and
          agree to be bound by the other terms and conditions and MotoDoctor policies (including but not limited to
          Cancellation &amp; Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the
          Platform from time to time.
        </p>
        <p>
          <strong>USE OF PLATFORM AND SERVICES</strong>
        </p>
        <ol>
          <li>
            All commercial/contractual terms are offered by and agreed to between Customer and Mechanic alone with
            respect to products and services being offered by the The commercial/contractual terms include without
            limitation price, applicable taxes, shipping costs, payment terms, date, period and mode of delivery,
            warranties related to products and services and after sales services related to products and services. Moto
            Doctor does not have any control or does not determine or advise or in any way involve itself in the
            offering or acceptance of such commercial/contractual terms between the Customer and Mechanic. MotoDoctor
            may, however, offer support services to Mechanic in respect to Service fulfilment, mode of payment, payment
            collection, call centre support and other ancillary services, pursuant to independent contracts executed by
            MotoDoctor with the Mechanic. The price of the product and services offered by the Mechanic are determined
            by the Mechanic itself and MotoDoctor has no role to play in such determination of price in any way
            whatsoever.
          </li>
          <li>
            MotoDoctor does not make any representation or warranty as to the item-specifics (such as legal title,
            creditworthiness, identity, etc.) of any of the Mechanic. You are advised to independently verify the bona
            fides of any particular Mechanic that you choose to deal with on the Platform and use your best judgment on
            that behalf. All Mechanic offers and third-party offers are subject to respective party terms and
            conditions. MotoDoctor takes no responsibility for such offers.
          </li>
          <li>
            MotoDoctor neither make any representation or warranty as to specifics (such as quality, value, saleability,
            etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform nor
            does implicitly or explicitly support or endorse the sale or purchase of any products or services on the
            Platform. MotoDoctor accepts no liability for any errors or omissions, whether on behalf of itself or third
            parties.
          </li>
          <li>
            MotoDoctor does not make any representation or warranty with respect to any aspect of the services being
            provided through the Platform including but not limited to pick up and delivery services and Task completion
            services to the Mechanic or Customers as the case may be.
          </li>
          <li>
            MotoDoctor is not responsible for any non-performance or breach of any contract entered into between
            Customer and Mechanic, and between Mechanic/Customer on the Platform. MotoDoctor cannot and does not
            guarantee that the concerned Customers, Mechanic &nbsp; will perform any transaction concluded on the
            Platform. MotoDoctor is not responsible for unsatisfactory or non-performance of services or damages or
            delays as a result of products which are out of stock, unavailable or back ordered.
          </li>
          <li>
            MotoDoctor is operating as an online digital platform &amp; marketplace and assumes the role of facilitator,
            and does not at any point of time during any transaction between Customer and Mechanic and/or Customer on
            the Platform come into or take possession of any of the products or services offered by Mechanic. At no time
            shall MotoDoctor hold any right, title or interest over the products nor shall MotoDoctor have any
            obligations or liabilities in respect of such contract entered into between Customer and Mechanic.
          </li>
          <li>
            MotoDoctor is only providing a platform for communication and it is agreed that the contract for sale of any
            of the products or services shall be a strictly bipartite contract between the Mechanic and the Customer. In
            case of complaints from the Customer pertaining to efficacy, quality, or any other such issues, MotoDoctor
            shall notify the same to Mechanic and shall also redirect the Customer to the consumer call centre of the
            Mechanic. The Mechanic shall be liable for redressing Customer complaints. In the event you raise any
            complaint on any Mechanic accessed using our Platform, we shall assist you to the best of our abilities by
            providing relevant information to you, such as details of the Mechanic and the specific Service to which the
            complaint relates, to enable satisfactory resolution of the complaint.
          </li>
          <li>
            Similar to the above, MotoDoctor is only providing a platform for communication and does not provide any
            pick-up and delivery services or Task completion services with respect to the Services placed by
            Mechanic/Customers on the Platform as it is merely facilitating Delivery Services by connecting the
            Mechanic/Customers with the through the Platform. In case of complaints by the Mechanic/Customers for
            deficiency or lapse in the delivery services or Task completion services provided by, MotoDoctor shall
            notify the same &nbsp;also assist Mechanic/Customers to the best of its abilities to enable satisfactory
            resolution of the complaint.
          </li>
          <li>
            Please note that there could be risks in dealing with underage persons or people acting under false
            pretence.
          </li>
          <li>
            Individuals below the age of 18 or minors are strictly prohibited from using this application, and
            MotoDoctor will not endorse or encourage their participation. Any violation of this condition absolves us of
            responsibility.
          </li>
        </ol>
        <p>
          <strong>MotoDoctor - Use of the Website and Apps </strong>
        </p>
        <p>
          You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding
          principles:
        </p>
        <ol>
          <li>
            You shall not host, display, upload, download, modify, publish, transmit, update or share any information
            which:
            <ul>
              <li>Belongs to another person and which you do not have any right to;</li>
              <li>
                Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous,
                slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically
                objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful
                in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to
                "indecent representation of women" within the meaning of the Indecent Representation of Women
                (Prohibition) Act, 1986;
              </li>
              <li>Is misleading or misrepresentative in any way;</li>
              <li>
                Is patently offensive to the online community, such as sexually explicit content, or content that
                promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group
                or individual;
              </li>
              <li>Harasses or advocates harassment of another person;</li>
              <li>
                Involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
              </li>
              <li>
                Promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
              </li>
              <li>
                Infringes upon or violates any third party's rights including, but not limited to, intellectual property
                rights, rights of privacy (including without limitation unauthorized disclosure of a person's name,
                email address, physical address or phone number) or rights of publicity;
              </li>
              <li>
                Promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint"
                below for instructions on how to lodge a complaint about uploaded copyrighted material), such as
                providing pirated computer programs or links to them, providing information to circumvent
                manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
              </li>
              <li>
                Promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint"
                below for instructions on how to lodge a complaint about uploaded copyrighted material), such as
                providing pirated computer programs or links to them, providing information to circumvent
                manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
              </li>
              <li>
                Contains restricted or password-only access pages, or hidden pages or images (those not linked to or
                from another accessible page);
              </li>
              <li>
                Provides material that exploits people in a sexual, violent or otherwise inappropriate manner or
                solicits personal information from anyone;
              </li>
              <li>
                Provides instructional information about illegal activities such as making or buying illegal weapons,
                violating someone's privacy, or providing or creating computer viruses;
              </li>
              <li>Contains video, photographs, or images of another person (with a minor or an adult);</li>
              <li>
                Tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to
                profiles, blogs, communities, account information, bulletins, friend request, or other areas of the
                Platform or solicits passwords or personal identifying information for commercial or unlawful purposes
                from other users;
              </li>
              <li>
                Engages in commercial activities and/or sales and/or services or the buying or selling of products
                related to the Platform. Throughout these Terms of Use, MotoDoctor's prior written consent means a
                communication coming from MotoDoctor's Legal Department, specifically in response to your request, and
                expressly addressing and allowing the activity or conduct for which you seek authorization;
              </li>
              <li>
                Solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
              </li>
              <li>
                Interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment
                of similar services;
              </li>
              <li>
                Refers to any website or URL that, in our sole discretion, contains material that is inappropriate for
                the Platform or any other website, contains content that would be prohibited or violates the letter or
                spirit of these Terms of Use;
              </li>
              <li>Harm minors in any way;</li>
              <li>
                Infringes any patent, trademark, copyright or other intellectual property rights or third party's trade
                secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit
                or stolen products;
              </li>
              <li>Violates any law for the time being in force;</li>
              <li>
                Deceives or misleads the addressee/users about the origin of such messages or communicates any
                information which is grossly offensive or menacing in nature;
              </li>
              <li>Impersonate another person;</li>
              <li>
                Contains software viruses or any other computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs,
                cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere
                with, diminish value of, surreptitiously intercept or expropriate any system, data or personal
                information;
              </li>
              <li>
                Threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with
                foreign states, or public order or causes incitement to the commission of any criminal offense or
                prevents investigation of any offense or is insulting any other nation;
              </li>
              <li>Is false, inaccurate or misleading;</li>
              <li>
                Directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing
                of which is prohibited or restricted in any manner under the provisions of any applicable law, rule,
                regulation or guideline for the time being in force; or
              </li>
              <li>
                Creates liability for us or causes us to lose (in whole or in part) the services of our internet service
                provider or other suppliers.
              </li>
            </ul>
          </li>
          <li>
            You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program,
            algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor
            any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure
            or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or
            information through any means not purposely made available through the Platform. We reserve our right to
            prohibit any such activity.
          </li>
          <li>
            You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other
            systems or networks connected to the Platform or to any server, computer, network, or to any of the services
            offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.
          </li>
          <li>
            You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform
            nor breach the security or authentication measures on the Platform or any network connected to the Platform.
            You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to
            Platform, or any other Customer, including any account on the Platform not owned by you, to its source, or
            exploit the Platform or any service or information made available or offered by or through the Platform, in
            any way where the purpose is to reveal any information, including but not limited to personal identification
            or information, other than your own information, as provided for by the Platform.
          </li>
          <li>
            You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand
            name or domain name used by us including the name 'MotoDoctor', or otherwise engage in any conduct or action
            that might tarnish the image or reputation, of MotoDoctor or Mechanic on platform or otherwise tarnish or
            dilute any MotoDoctor's trade or service marks, trade name and/or goodwill associated with such trade or
            service marks, as may be owned or used by us. You agree that you will not take any action that imposes an
            unreasonable or disproportionately large load on the infrastructure of the Platform or MotoDoctor's systems
            or networks, or any systems or networks connected to MotoDoctor.
          </li>
          <li>
            You agree not to use any device, software or routine to interfere or attempt to interfere with the proper
            working of the Platform or any transaction being conducted on the Platform, or with any other person's use
            of the Platform.
          </li>
          <li>
            You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message
            or transmittal you send to us on or through the Platform or any service offered on or through the Platform.
            You may not pretend that you are, or that you represent, someone else, or impersonate any other individual
            or entity.
          </li>
          <li>
            You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited
            by these Terms of Use, or to solicit the performance of any illegal activity or other activity that
            infringes the rights of MotoDoctor and/or others.
          </li>
          <li>
            You shall at all times ensure full compliance with the applicable provisions, as amended from time to time,
            of
            <ol>
              <li>the Information Technology Act, 2000 and the rules thereunder</li>
              <li>
                all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange
                control laws or regulations in force); and
              </li>
              <li>
                international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not
                limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies)
                regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and
                sale of products or services. You shall not engage in any transaction in an item or service, which is
                prohibited by the provisions of any applicable law including exchange control laws or regulations for
                the time being in force.
              </li>
            </ol>
          </li>
          <li>
            In order to allow us to use the information supplied by you, without violating your rights or any laws, you
            agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through
            multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in
            your Information, in any media now known or not currently known, with respect to your Information. We will
            only use your information in accordance with these Terms of Use and Privacy Policy applicable to use of the
            Platform.
          </li>
          <li>
            From time to time, you shall be responsible for providing information relating to the products or services
            proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in
            all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to
            mislead other users in any manner.
          </li>
          <li>
            You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any
            products or services, including, but not limited to, products or services related to that being displayed on
            the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk
            email to other users via the Platform. It shall be a violation of these Terms of Use to use any information
            obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact,
            advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order
            to protect our users from such advertising or solicitation, we reserve the right to restrict the number of
            messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in
            its sole discretion. You understand that we have the right at all times to disclose any information
            (including the identity of the persons providing information or materials on the Platform) as necessary to
            satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure
            of the information in connection with investigation of alleged illegal activity or solicitation of illegal
            activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly
            authorize us to) disclose any information about you to law enforcement or other government officials, as we,
            in our sole discretion, believe necessary or appropriate in connection with the investigation and/or
            resolution of possible crimes, especially those that may involve personal injury.
          </li>
          <li>
            We reserve the right, but has no obligation, to monitor the materials posted on the Platform. MotoDoctor
            shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to
            violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this
            right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR
            PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect MotoDoctor views.
            In no event shall MotoDoctor assume or have any responsibility or liability for any Content posted or for
            any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform.
            You hereby represent and warrant that you have all necessary rights in and to all Content which you provide
            and all information it contains and that such Content shall not infringe any proprietary or other rights of
            third parties or contain any libelous, tortious, or otherwise unlawful information.
          </li>
          <li>
            Your correspondence or business dealings with, or participation in promotions of, advertisers found on or
            through the Platform, including payment and delivery of related products or services, and any other terms,
            conditions, warranties or representations associated with such dealings, are solely between you and such
            advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result
            of any such dealings or as the result of the presence of such advertisers on the Platform.
          </li>
          <li>
            It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive
            or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene
            materials. It also is possible for others to obtain personal information about you due to your use of the
            Platform, and that the recipient may use such information to harass or injure you. We do not approve of such
            unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the
            use of any personal information that you publicly disclose or share with others on the Platform. Please
            carefully select the type of information that you publicly disclose or share with others on the Platform.
          </li>
          <li>
            MotoDoctor shall have all the rights to take necessary action and claim damages that may occur due to your
            involvement/participation in any way on your own or through group/s of people, intentionally or
            unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our
            prior consent or a mutual legal agreement.
          </li>
        </ol>
        <p>
          <strong>ACCOUNT REGISTRATION</strong>
        </p>
        <ol>
          <li>
            You may access the Platform by registering to create an account (" MotoDoctor Account") and become a member
            (" Membership"); or you can also register to join by logging into your account with certain third party
            social networking sites ("SNS") (including, but not limited to, Facebook); each such account, a " Third
            Party Account", via our Platform, as described below. The Membership is limited for the purpose and are
            subject to the terms, and strictly not transferable. As part of the functionality of the Platform services,
            you may link your MotoDoctor Account with Third Party Accounts, by either:
            <ul>
              <li>Providing your Third Party Account login information to us through the Platform; or</li>
              <li>
                allowing us to access your Third Party Account, as is permitted under the applicable terms and
                conditions that govern your use of each Third Party Account.
              </li>
            </ul>
          </li>
          <li>
            You represent that you are entitled to disclose your Third Party Account login information to us and/or
            grant us access to your Third Party Account (including, but not limited to, for use for the purposes
            described herein), without breach by you of any of the terms and conditions that govern your use of the
            applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage
            limitations imposed by such third party service providers.
          </li>
          <li>
            By granting us access to any Third Party Accounts, you understand that we will access, make available and
            store (if applicable) any content or information that you have provided to and stored in your Third Party
            Account (" SNS Content") so that it is available on and through the Platform via your MotoDoctor Account.
          </li>
          <li>
            Unless otherwise specified in these Terms of Use, all SNS Content, if any, will be considered to be your
            content for all purposes of these Terms of Use.
          </li>
          <li>
            Depending on the Third-Party Accounts, you choose, and subject to the privacy settings that you have set in
            such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts
            will be available on and through your MotoDoctor Account on the Platform.
          </li>
          <li>
            Please note that if a Third-Party Account or associated service becomes unavailable or our access to such
            Third-Party Account is terminated by the third-party service provider, then SNS Content will no longer be
            available on and through the Platform.
          </li>
          <li>
            We will create your MotoDoctor Account for your use of the Platform services based upon the personal
            information you provide to us or that we obtain via SNS, as described above. You can only have one
            MotoDoctor Account and are not permitted to create multiple accounts. MotoDoctor reserves the right to
            suspend such multiple accounts without being liable for any compensation where you have created multiple
            accounts on the Platform.
          </li>
          <li>
            You agree to provide accurate, current and complete information during the registration process and update
            such information to keep it accurate, current and complete.
          </li>
          <li>
            We reserve the right to suspend or terminate your MotoDoctor Account and your access to the Services
            <ul>
              <li>
                if any information provided during the registration process or thereafter proves to be inaccurate, not
                current or incomplete
              </li>
              <li>if it is believed that your actions may cause legal liability for you, other users or us; and/or</li>
              <li>if you are found to be non-compliant with the Terms of Use or other MotoDoctor policies.</li>
            </ul>
          </li>
          <li>
            You are responsible for safeguarding your password. You agree that you will not disclose your password to
            any third party and that you will take sole responsibility for any activities or actions under your
            MotoDoctor Account, whether or not you have authorized such activities or actions. You will immediately
            notify us of any unauthorized use of your MotoDoctor Account.
          </li>
          <li>
            Goods and services purchased from the Platform are intended for your personal use and you represent that the
            same are not for resale or you are not acting as an agent for other parties.
          </li>
        </ol>
        <p>
          <strong>SERVICE BOOKING AND FINANCIAL TERMS</strong>
        </p>
        <ol>
          <li>
            The Platform allows the Customers to place Services and upon acceptance of such Services by the Mechanic,
            MotoDoctor will, subject to the terms and conditions set out herein, facilitates delivery of goods or
            services.
          </li>
          <li>
            MotoDoctor does not own, sell, resell on its own such products offered by the Mechanic, and/or does not
            control the Mechanic or the related services provided in connection thereof. Customer understands that any
            Service that he/she places shall be subject to the terms and conditions set out in these Terms of Use
            including, but not limited to, product availability, service location serviceability, and acceptance of
            Services by Mechanic
          </li>
          <li>
            As a general rule, all Services placed on the Platform and Delivery Services are treated as confirmed.
          </li>
          <li>
            However, upon Customer&rsquo;s successful completion of booking an Service, we may call the Customer on the
            telephone or mobile number provided to confirm the details of such Service, price to be paid and the
            estimated Service delivery time. For this purpose, Customer will be required to share certain information
            with us, including but not limited to Customer&rsquo;s (i)first and last name (ii)mobile number; and
            (iii)email address. It shall be Customer&rsquo;s sole responsibility to bring any incorrect details to our
            attention.
          </li>
          <li>
            In addition to the foregoing, we may also contact you by phone and / or email to inform and confirm any
            change in the Service, due to availability or unavailability or change in Service or change in price of any
            item in the Service as informed by the Mechanic. Please note that any change or confirmation of the Service
            shall be treated as final. It is clarified that MotoDoctor reserves the right to not to process Customer's
            Service in the event Customer or Mechanic is unavailable on the phone or any other means of communication at
            the time when we call you for confirming the Service and such event the provisions of the Cancellation and
            Refund Policy shall be applicable.
          </li>
          <li>
            All payments made against the Services or Services on the Platform by you shall be compulsorily in Indian
            Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to
            any other form of currency with respect to the Services or Services made on Platform. You can pay by (i)
            credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking
            an Service; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree
            that the payment facility provided by MotoDoctor is neither a banking nor financial service but is merely a
            facilitator providing an electronic, automated online electronic payment, receiving payment on delivery,
            collection and remittance facility for the transactions on the Platform using the existing authorized
            banking infrastructure and credit card payment gateway networks. Further, by providing payment facility,
            MotoDoctor is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction
            or the transaction price.
          </li>
          <li>
            For Delivery Services, Customers acknowledge and agree that MotoDoctor acts as the Mechanic's payment agent
            for the limited purpose of accepting payments from Customers/Mechanic on behalf of the Mechanic, as the case
            may be. Upon your payment of amounts to us, which are due to the Mechanic, your payment obligation to the
            Mechanic for such amounts is completed, and we are responsible for remitting such amounts to the Mechanic.
            You shall not, under any circumstances whatsoever, make any payment directly to the Mechanic for Service
            bookings for delivery of the Service or completion of the Task made using the Platform.
          </li>
          <li>
            Repair Services: Customers acknowledge and agree that MotoDoctor acts as the Mechanic's payment agent for
            the limited purpose of accepting payments from Customers/Mechanic on behalf of the Mechanic, as the case may
            be. Upon your payment of amounts to us, which are due to the Mechanic, your payment obligation to the
            Mechanic for such amounts is completed, and we are responsible for remitting such amounts to the Mechanic in
            case you make online payments directly on MotoDoctor platform. You can make payments directly to the
            Mechanic for delivery of the Service or completion of the Task made using the Platform which is a direct
            interaction between Customer &amp; Mechanic, and MotoDoctor will not be held responsible for any concern or
            issue or liability which arises out of the direct payment.
          </li>
          <li>
            Customer agrees to pay for the total amount for the Service placed on the Platform. MotoDoctor or Mechanic
            will collect the total amount in accordance with these Terms of Use and the pricing terms set forth in the
            applicable listing of product or repair service for the particular Mechanic, apart from the delivery fees
            for Delivery Services. Please note that we cannot control any amount that may be charged to the Customer by
            his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.
          </li>
          <li>
            In connection with Customer&rsquo;s Service, he/she will be asked to provide customary billing information
            such as name, billing address and credit card information either to us or our third-party payment processor.
            Customer agrees to pay us for the Service placed by you on the Platform, in accordance with these Terms,
            using the methods described under clause, MotoDoctor categorizes geographical locations into three zones,
            and in the free zone, mechanics are entitled to Rs.50 for the initial 2 km, with an additional Rs.15 for
            each extra kilometre. Mechanics have the right to claim only the upward one-way charges and are not eligible
            to claim both-way costs.se VIII (6) above. Customer hereby authorizes the collection of such amounts by
            charging the credit card provided as part of requesting the booking, either directly by us or indirectly,
            via a third-party online payment processor or by one of the payment methods described on the Platform. If
            Customer is directed to our third-party payment processor, he/she may be subject to terms and conditions
            governing use of that third party's service and that third party's personal information collection
            practices. Please review such terms and conditions and privacy policy before using the Platform services.
          </li>
          <li>
            The final tax bill will be issued by the Mechanic (if registered for tax purposes) to the Customer along
            with the Service and MotoDoctor is merely collecting the payment on behalf of such Mechanic. All applicable
            taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being
            charged and determined by the Mechanic. MotoDoctor holds no responsibility for the legal
            correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the
            taxes shall reside with the Mechanic.
          </li>
          <li>
            The prices reflected on the Platform, including packaging or handling charges, are determined solely by the
            Mechanic and are listed based on Mechanic's information. Very rarely, prices may change at the time of
            placing Service due to Mechanic changing the menu price without due intimation and such change of price are
            at the sole discretion of the Mechanic attributing to various factors beyond control.
          </li>
        </ol>
        <p>
          <strong>Disclaimer:</strong> Prices on any product(s) as reflected on the Platform may due to some technical
          issue, typographical error or product information supplied by Mechanic be incorrectly reflected and in such an
          event Mechanic may cancel Customer&rsquo;s Service(s).
        </p>
        <ol start="13">
          <li>
            The Mechanic shall be solely responsible for any warranty/guarantee of the goods or services sold to the
            Customers and in no event shall be the responsibility of MotoDoctor.
          </li>
          <li>
            The transactions are bilateral between the Mechanic and Customer, and between Mechanic/Customer therefore,
            MotoDoctor is not liable to charge or deposit any taxes applicable on such transactions.
          </li>
          <li>
            MotoDoctor facilitates payment for all service and repair costs through the designated payment method. We
            strongly encourage customers to utilize this specified payment method. Any cash transactions or alternative
            methods are not the responsibility or liability of MotoDoctor.
          </li>
          <li>
            MotoDoctor emphasizes that when adding mechanic bank details for payment processing, MotoDoctor officials or
            representatives will never request OTPs or banking credentials. Mechanics are discouraged from engaging in
            such activities, and any resulting loss will not be the responsibility of MotoDoctor.
          </li>
          <li>
            MotoDoctor has categorized geographical locations into three zones, with zone one covering a distance of 3
            km. Mechanics are entitled to a payment of Rs.50 for the first 2 km, and an additional Rs.15 for each extra
            kilometre. Mechanics have the right to claim both the upward and downward travel costs.
          </li>
          <li>
            MotoDoctor classifies geographical locations into three zones, and zone two spans a distance of 3 to 6 km.
            Mechanics are eligible for Rs.50 for the initial 2 km and an extra Rs.15 for each additional kilometre.
            Mechanics retain the right to claim both upward and downward costs. However, for downward travel, MotoDoctor
            will provide 25% of the upward charges.
          </li>
          <li>
            MotoDoctor categorizes geographical locations into three zones, and in the free zone, mechanics are entitled
            to Rs.50 for the initial 2 km, with an additional Rs.15 for each extra kilometre. Mechanics have the right
            to claim only the upward one-way charges and are not eligible to claim both-way costs.
          </li>
        </ol>
        <br />
        <p>
          <strong>Indoor and Outdoor MotoDoctor Mechanic and Mechanics</strong>
        </p>
        <li>
          Scope of Services: Indoor services include repairs, maintenance, and diagnostics performed at
          MotoDoctor-approved service centers.Outdoor services refer to on-site mechanic assistance provided at the
          customer’s location by MotoDoctor mechanics or authorized service personnel.
        </li>
        <li>
          Service Availability: Indoor and outdoor services are subject to the availability of mechanics and the nature
          of the issue reported.Outdoor services may be limited to specific geographical areas as determined by
          MotoDoctor.
        </li>
        <li>
          Customer Responsibilities: For outdoor services, customers must ensure the safety and accessibility of the
          location for mechanics.Customers must provide accurate details of the issue and vehicle specifications during
          the service request.
        </li>
        <li>
          Liability for Services: MotoDoctor ensures that mechanics deliver services with due diligence and
          professionalism. However, MotoDoctor shall not be held responsible for delays or service failures due to
          unforeseen circumstances or factors beyond its control.
        </li>
        <li>
          Service Fees: Additional charges may apply for outdoor services, including but not limited to travel costs and
          emergency service fees. These charges will be communicated to the customer prior to service initiation.
        </li>
        <li>
          Service Quality: MotoDoctor commits to providing quality services and using genuine spare parts where
          applicable. For outdoor services, the scope may be limited by the availability of required tools or resources.
        </li>
        <li>
          Dispute Resolution: Any disputes arising from indoor or outdoor services shall be addressed per MotoDoctor’s
          dispute resolution policy as outlined in the general Terms of Use.
        </li>
        <br />
        <p>
          <strong>Spares Procurement from Third-Party Manufacturers</strong>
        </p>
        <li>
          Third-Party Sourcing: MotoDoctor may procure spare parts from third-party manufacturers based on customer
          requests or service requirements.
        </li>
        <li>
          Warranty and Liability: MotoDoctor does not provide warranties for spare parts sourced from third-party
          manufacturers.MotoDoctor shall not be held liable for any defects, malfunctions, or incompatibilities arising
          from the use of such third-party spare parts.
        </li>
        <li>
          Customer Acknowledgment: By requesting or agreeing to the use of third-party spare parts, the customer
          acknowledges and accepts full responsibility for any risks associated with their usage.
        </li>
        <br />
        <p>
          <strong>Offers and Promotions</strong>
        </p>
        <li>
          Eligibility: Offers and promotions are available only to users who meet the specific criteria mentioned in the
          offer details. MotoDoctor reserves the right to determine eligibility.
        </li>
        <li>
          Applicability: Offers are valid only on labor charges for services availed through the MotoDoctor platform.
          Discounts or benefits do not apply to spare parts, consumables, or any additional charges.{" "}
        </li>
        <li>
          Limited Period: Offers are valid for the period specified in the promotion. MotoDoctor may modify or withdraw
          any offer at its sole discretion without prior notice.
        </li>
        <li>
          Non-Transferable: Offers cannot be transferred, exchanged, or redeemed for cash. They are applicable only to
          the registered user account for which the offer was issued.
        </li>
        <li>
          Usage Limits: Each user can avail of an offer only once unless explicitly stated otherwise in the offer terms.
        </li>
        <li>
          Combination of Offers: Unless mentioned, no two offers can be combined or used simultaneously on the same
          service.
        </li>
        <li>
          Cancellation: If a booking associated with an offer is canceled, the offer will not be reactivated, unless
          specified.
        </li>
        <li>
          Fraudulent Use: MotoDoctor reserves the right to cancel or suspend any offer if fraudulent or misuse activity
          is detected, including but not limited to the creation of multiple accounts.
        </li>
        <li>
          Modification Rights: MotoDoctor reserves the right to modify, suspend, or terminate any offer or promotion
          without liability or notice, at its sole discretion.
        </li>
        <li>
          Disclaimer: MotoDoctor is not liable for any loss or inconvenience caused due to the unavailability,
          modification, or withdrawal of an offer.
        </li>
        <li>
          Disputes: Any disputes regarding offers and promotions will be resolved at MotoDoctor’s discretion, and the
          decision will be final and binding.
        </li>
        <br />
        <p>
          <strong>CANCELLATIONS AND REFUNDS</strong>
        </p>
        <p>
          Please refer to the Cancellation and Refund Policy for cancellation and refunds terms in relation to usage of
          the Platform for availing Services.
        </p>
        <ol>
          <li>
            MotoDoctor's refund policy for repair and service applications is as follows: Refunds are only applicable in
            cases where the service is not provided by mechanic. Requests for refunds must be submitted within a
            specified timeframe, and eligibility will be determined based on the assessment of the service outcome.
            Refunds are not granted for issues arising from user error or external factors beyond MotoDoctor's control.
            The decision regarding refund approval rests with MotoDoctor, and any such decision is final.
          </li>
          <li>
            The timeframe for refund requests within MotoDoctor's Terms and Conditions is specified and must be adhered
            to. It is essential to review and understand this timeframe as outlined in the terms and conditions document
            provided by MotoDoctor Refund requests outside of this specified period may not be considered eligible.
          </li>
          <li>
            MotoDoctor Repayments will only be initiated to the source of income account provided and are not applicable
            to other accounts. It's crucial to ensure that the specified income account details are accurate for a
            successful repayment process.
          </li>
        </ol>
        <br />
        <p>
          <strong>TERMS OF SERVICE</strong>
        </p>
        <ol>
          <li>
            The Customer agrees and acknowledges that MotoDoctor shall not be responsible for:
            <ol>
              <li>
                The services or goods provided by the Mechanic including but not limited to repair services, general
                services, and selling automobile spares;
              </li>
              <li>
                The Mechanic's services or goods &nbsp;not being up to Customer expectations or leading to any loss,
                harm or damage to him/her;
              </li>
              <li>The availability or unavailability of certain repairs/automobile spares on the services;</li>
              <li>The Mechanic serving the incorrect services/automobile spares; or</li>
              <li>Product liability of goods provided by Mechanic.</li>
            </ol>
          </li>
          <li>
            The details of the repairs and price list available on the Platform with respect to repair services,
            automobile or any other services are based on the information provided by the Mechanic and MotoDoctor shall
            not be responsible for any change or cancellation or unavailability.
          </li>
          <li>
            Customers may not be able to avail Services if their delivery location is outside MotoDoctor&rsquo;s current
            scope of Service. MotoDoctor will keep the Customer informed of the same at the time of confirming his/her
            Service booking.
          </li>
          <li>
            Customer understands that mechanic reaching time quoted at the time of confirming the Service is an
            approximate estimate and may vary based on the information obtained from Mechanic. MotoDoctor will not be
            responsible for any delay in the delivery of a Service.
          </li>
          <li>
            Customer understands that there are certain mechanics who undertake delivery of their goods and services to
            the Customer and the Mechanic may charge the Customer for such service. MotoDoctor exercises no control on
            such delivery services and the same shall be under the control of Mechanic alone and hence all or any
            disputes arising out of such delivery services shall be between Customer and Mechanic alone. MotoDoctor
            shall not be responsible for such repair services and assumes no liability for disputes arising out of the
            same.
          </li>
          <li>
            Customer&rsquo;s Service will be only performed to the location designated by him/her at the time of placing
            the Service on the Platform. Customer&rsquo;s Service will be cancelled in the event of any change of the
            location as informed by the Customer shall not be entitled to any refund for the same. Delivery of goods and
            services in the event of change of the services location shall be subject to acceptance by the mechanic or
            sole discretion of MotoDoctor.
          </li>
          <li>
            The Customer shall undertake to provide adequate directions, information and authorisations to avail
            service. In the event of no delivery due to any act or omission attributable to Customer, the goods or
            services shall be deemed to have been delivered to the Customer and all risk and responsibility in relation
            thereto shall pass to the Customer without being entitled to any refund.
          </li>
          <li>
            The Customer understands that MotoDoctor&rsquo;s (including Mechanic&rsquo;s) liability ends once Service
            has been completed to him/her, except where the product liability of the Mechanic subsists.
          </li>
        </ol>
        <br />
        <p>
          <strong>SERVICES PROVIDED</strong>
        </p>
        <ol>
          <li>
            You agree and acknowledge that MotoDoctor shall be liable in the event you have failed to adhere to the
            Terms of Use.
          </li>
          <li>
            Customers shall be required to provide credit or debit card details to the approved payment gateways while
            making the payment on the Platform. In this regard, Customer agrees to provide correct and accurate credit/
            debit card details to the approved payment gateways for availing the Services. Customer shall not use the
            credit/ debit card which is not lawfully owned by Customer, i.e. in any transaction, Customer must use
            his/her own credit/ debit card. The information provided by the Customer will not be utilized or shared with
            any third party unless required in relation to fraud verifications or by law, regulation or court order.
            Customer shall be solely responsible for the security and confidentiality of his/her credit/ debit card
            details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of
            your credit/ debit card.
          </li>
        </ol>
        <p>
          MotoDoctor does not offer any refunds against goods or services already purchased from a Mechanic through the
          Platform unless an error that is directly attributable to MotoDoctor has occurred during the purchase of such
          product or services.
        </p>
        <p>
          We constantly strive to provide you with accurate information on the Platform. However, in the event of an
          error, we may, in our sole discretion, contact you with further instructions.
        </p>
        <p>
          By using the Platform, you acknowledge and agree that you do so at your own risk. MotoDoctor is not
          responsible for any damages, losses, or injuries that may result from the use of the services provided by
          mechanics, third-party service providers, or from interactions facilitated through the Platform
        </p>
        <p>
          Customer agrees that the Services shall be provided through the Platform only during the working hours of the
          relevant Mechanic.
        </p>
        <br />
        <p>
          <strong>NO ENDORSEMENT</strong>
        </p>
        <ol>
          <li>
            We do not endorse any Mechanic. In addition, although these Terms of Use require you to provide accurate
            information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be
            responsible for any damage or harm resulting from your interactions with other Members.
          </li>
          <li>
            By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or
            omissions of other Members or other third parties will be limited to a claim against the particular Members
            or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any
            legal remedy from us with respect to such actions or omissions.
          </li>
        </ol>
        <br />
        <p>
          <strong>GENERAL</strong>
        </p>
        <ol>
          <li>
            Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including
            minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18
            years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish
            to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree
            to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge
            the Terms of Use and MotoDoctor policies, you shall immediately discontinue its use. MotoDoctor reserves the
            right to terminate your Membership and / or deny access to the platform if it is brought to MotoDoctor's
            notice that you are under the age of 18 years.
          </li>
          <li>
            If you choose to use the Platform, it shall be your responsibility to treat your user identification code,
            password and any other piece of information that we may provide, as part of our security procedures, as
            confidential and not disclose the same to any person or entity other than us. We shall at times and at our
            sole discretion reserve the right to disable any user identification code or password if you have failed to
            comply with any of the provisions of these Terms of Use.
          </li>
          <li>
            As we are providing services in the select cities in India, we have complied with applicable laws of India
            in making the Platform and its content available to you. In the event the Platform is accessed from outside
            India or outside our service zones, it shall be entirely at your risk. We make no representation that the
            Platform and its contents are available or otherwise suitable for use outside select cities. If you choose
            to access or use the Platform from or in locations outside select cities, you do so on your own and shall be
            responsible for the consequences and ensuring compliance of applicable laws, regulations, byelaws, licenses,
            registrations, permits, authorisations, rules and guidelines.
          </li>
          <li>
            You shall at all times be responsible for the use of the Services through your computer or mobile device and
            for bringing these Terms of Use and MotoDoctor policies to the attention of all such persons accessing the
            Platform on your computer or mobile device.
          </li>
          <li>
            You understand and agree that the use of the Services does not include the provision of a computer or mobile
            device or other necessary equipment to access it. You also understand and acknowledge that the use of the
            Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to
            access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be
            responsible or liable for such costs.
          </li>
          <li>
            You agree and grant permission to MotoDoctor to receive promotional SMS and e-mails from MotoDoctor or
            allied partners. In case you wish to opt out of receiving promotional SMS or email please send a mail to{" "}
            <a href="mailto:support@motodoctor.in">support@motodoctor.in</a>
          </li>
        </ol>
        <br />
        <p>
          <strong>BY USING THE PLATFORM YOU REPRESENT AND WARRANT THAT</strong>
        </p>
        <ol>
          <li>
            All registration information you submit is truthful, lawful and accurate and that you agree to maintain the
            accuracy of such information.
          </li>
          <li>
            Your use of the Platform shall be solely for your personal use and you shall not authorize others to use
            your account, including your profile or email address and that you are solely responsible for all content
            published or displayed through your account, including any email messages, and your interactions with other
            users and you shall abide by all applicable local, state, national and foreign laws, treaties and
            regulations, including those related to data privacy, international communications and the transmission of
            technical or personal data.
          </li>
          <li>
            You will not submit, post, upload, distribute, or otherwise make available or transmit any content that:
            <ul>
              <li>
                is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or
                constitute an invasion of a right of privacy of another person
              </li>
              <li>is bigoted, hateful, or racially or otherwise offensive</li>
              <li>is violent, vulgar, obscene, pornographic or otherwise sexually explicit;</li>
              <li>
                is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the
                intent to commit them.
              </li>
            </ul>
          </li>
          <li>
            All necessary licenses, consents, permissions and rights are owned by you and there is no need for any
            payment or permission or authorization required from any other party or entity to use, distribute or
            otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks,
            copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained
            in any content that you submit, post, upload, distribute or otherwise transmit or make available.
          </li>
          <li>
            You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b)
            advertise or sell any products, services or otherwise (whether or not for profit), or solicit others
            (including, without limitation, solicitations for contributions or donations) or use any public forum for
            commercial purposes of any kind. In the event you want to advertise your product or service, contact&nbsp;
            <a href="mailto:support@motodoctor.in">support@motodoctor.in</a>
          </li>
          <li>
            You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as
            determined in our sole discretion.
          </li>
          <li>
            You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other
            computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform
            or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the
            Platform.
          </li>
          <li>
            You will not use another person's username, password or other account information, or another person's name,
            likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or
            affiliation with any person or entity.
          </li>
          <li>
            You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming,"
            "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the
            Internet.
          </li>
          <li>
            You will not delete or modify any content of the Platform, including but not limited to, legal notices,
            disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or
            have express permission to modify.
          </li>
          <li>
            You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar,
            profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening,
            embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our
            interest.
          </li>
          <li>
            You shall not access the Platform without authority or use the Platform in a manner that damages, interferes
            or disrupts:
            <ul>
              <li>any part of the Platform or the Platform software; or</li>
              <li>any equipment or any network on which the Platform is stored or any equipment of any third party</li>
            </ul>
          </li>
          <li>
            You release and fully indemnify MotoDoctor and/or any of its officers and representatives from any cost,
            damage, liability or other consequence of any of the actions of the Users of the Platform and specifically
            waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its
            reasonable efforts in that behalf, MotoDoctor cannot take responsibility or control the information provided
            by other Users which is made available on the Platform. You may find other User's information to be
            offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading
            when using the Platform.
          </li>
        </ol>
        <br />
        <p>
          <strong>ACCESS TO PLATFORM, ACCURACY, SECURITY</strong>
        </p>
        <ol>
          <li>
            We endeavour to make the Services available during Mechanic working hours. However, we do not represent that
            access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful
            components or that such defects will be corrected.
          </li>
          <li>
            We do not warrant that the Platform will be compatible with all hardware and software which you may use. We
            shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but
            not limited to your mobile device), software, data or other property as a result of your download,
            installation, access to or use of the Platform or your obtaining any material from, or as a result of using,
            the Platform. We shall also not be liable for the actions of third parties.
          </li>
          <li>
            We do not represent or warranty that the information available on the Platform will be correct, accurate or
            otherwise reliable.
          </li>
          <li>
            We reserve the right to suspend or withdraw access to the Platform to you personally, or to all users
            temporarily or permanently at any time without notice. We may any time at our sole discretion reinstate
            suspended users. A suspended User may not register or attempt to register with us or use the Platform in any
            manner whatsoever until such time that such user is reinstated by us.
          </li>
        </ol>
        <br />
        <p>
          <strong>RELATIONSHIP WITH OPERATORS IF THE PLATFORM IS ACCESSED ON MOBILE DEVICES</strong>
        </p>
        <ol>
          <li>
            In the event the Platform is accessed on a mobile device, it is not associated, affiliated, sponsored,
            endorsed or in any way linked to any platform operator, including, without limitation, Apple, Google,
            Android or RIM Blackberry (each being an " Operator").
          </li>
          <li>
            Your download, installation, access to or use of the Platform is also bound by the terms and conditions of
            the Operator.
          </li>
          <li>
            You and we acknowledge that these Terms of Use are concluded between you and MotoDoctor only, and not with
            an Operator, and we, not those Operators, are solely responsible for the Platform and the content thereof to
            the extent specified in these Terms of Use.
          </li>
          <li>
            The license granted to you for the Platform is limited to a non-transferable license to use the Platform on
            a mobile device that you own or control and as permitted by these Terms of Use.
          </li>
          <li>
            We are solely responsible for providing any maintenance and support services with respect to the Platform as
            required under applicable law. You and we acknowledge that an Operator has no obligation whatsoever to
            furnish any maintenance and support services with respect to the Platform.
          </li>
          <li>
            You and we acknowledge that we, not the relevant Operator, are responsible for addressing any claims of you
            or any third party relating to the Platform or your possession and/or use of the Platform, including, but
            not limited to:
            <ol>
              <li>
                any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and
              </li>
              <li>claims arising under consumer protection or similar legislation.</li>
            </ol>
          </li>
          <li>
            You and we acknowledge that, in the event of any third-party claim that the Platform or your possession and
            use of the Platform infringes that third party's intellectual property rights, we, not the relevant
            Operator, will be solely responsible for the investigation, defence, settlement and discharge of any such
            intellectual property infringement claim.
          </li>
          <li>
            You must comply with any applicable third-party terms of agreement when using the Platform (e.g. you must
            ensure that your use of the Platform is not in violation of your mobile device agreement or any wireless
            data service agreement).
          </li>
          <li>
            You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third
            party beneficiaries of these Terms of Use, and that, upon your acceptance of these Terms of Use, that
            Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use
            against you as a third party beneficiary thereof.
          </li>
        </ol>
        <br />
        <p>
          <strong>DISCLAIMERS</strong>
        </p>
        <ol>
          <li>
            THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.
          </li>
          <li>
            DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS
            INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR
            INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS
            A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.
          </li>
          <li>
            WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/
            DEBIT CARDS.
          </li>
          <li>
            YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR
            ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN
            SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR
            WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO,
            ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU
            HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S /
            MECHANIC&rsquo;S SERVICES.
          </li>
          <li>
            MOTODOCTOR DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING
            THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE MECHANIC.
          </li>
          <li>
            WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE
            SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE
            UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR
            IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION
            HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL
            WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH
            INFORMATION.
          </li>
          <li>
            THE INFORMATION PROVIDED HEREUNDER IS PROVIDED "AS IS". WE AND / OR OUR EMPLOYEES MAKE NO WARRANTY OR
            REPRESENTATION REGARDING THE TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY
            INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED
            UPON. MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGMENT
            OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN
            CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS
            ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.
          </li>
          <li>
            WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE
            INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE
            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT
            CONTAINED HEREIN.
          </li>
          <li>
            IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND
            DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES
            PROVIDED BY ANY THIRD PARTY OR MECHANIC ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT,
            TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </li>
        </ol>
        <br />
        <p>
          <strong>INTELLECTUAL PROPERTY</strong>
        </p>
        <ol>
          <li>
            We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual,
            irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual
            property, in the Platform, and in the material published on it including but not limited to user interface,
            layout format, Service placing process flow and any content thereof.
          </li>
          <li>
            You recognize that MotoDoctor is the registered owner of the word mark &lsquo;MotoDoctor&rsquo; and the logo
            including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist
            another in attacking the validity of, or MotoDoctor&rsquo;s or its affiliates proprietary rights in, the
            licensed marks or any registrations thereof, or file any applications for the registration of the licensed
            marks or any names or logos derived from or confusingly similar to the licensed marks, any variation
            thereof, or any translation or transliteration thereof in another language, in respect of any
            products/services and in any territory throughout the world. If you become aware or acquire knowledge of any
            infringement of IPR you shall report the same at&nbsp;
            <a href="mailto:support@motodoctor.in">support@motodoctor.in</a> with all relevant information.
          </li>
          <li>
            You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal
            reference and you may draw the attention of others within your organization to material available on the
            Platform.
          </li>
          <li>
            You must not modify the paper or digital copies of any materials you have printed off or downloaded in any
            way, and you must not use any illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </li>
          <li>
            You must not use any part of the materials on the Platform for commercial purposes without obtaining a
            license to do so from us or our licensors.
          </li>
          <li>
            If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to
            use the Platform will cease immediately and you must, at our option, return or destroy any copies of the
            materials you have made.
          </li>
        </ol>
        <br />
        <p>
          <strong>TREATMENT OF INFORMATION PROVIDED BY YOU</strong>
        </p>
        <p>We process information provided by you to us in accordance with our Privacy Policy.</p>
        <br />
        <p>
          <strong>THIRD</strong>
          <strong>-</strong>
          <strong>PARTY CONTENT</strong>
        </p>
        <ol>
          <li>
            We cannot and will not assure that other users are or will be complying with the foregoing rules or any
            other provisions of these Terms of Use, and, as between you and us, you hereby assume all risk of harm or
            injury resulting from any such lack of compliance.
          </li>
          <li>
            You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not
            controlled by us and different terms of use and Privacy Policy may apply. By assessing links to other sites,
            you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and /
            or from third-party sites to the Platform, although we are under no obligation to do so.
          </li>
        </ol>
        <br />
        <p>
          <strong>SEVERABILITY</strong>
        </p>
        <p>
          If any of these Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason
          of the laws of any state or country in which these Terms of Use are intended to be effective, then to the
          extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed
          and deleted and the remaining Terms of Use shall survive, remain in full force and effect and continue to be
          binding and enforceable.
        </p>
        <br />
        <p>
          <strong>NON-ASSIGNMENT</strong>
        </p>
        <p>
          You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other
          person.
        </p>
        <p>
          <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
        </p>
        <p>
          These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is
          commenced to resolve any matter arising under or relating to this Platform, shall be subject to the
          jurisdiction of the courts at Bangalore, India.
        </p>
        <p>
          <strong>IP NOTICE AND TAKE DOWN POLICY</strong>
        </p>
        <ol>
          <li>
            MotoDoctor has put in place IP Notice and Take Down Policy (" Take Down Policy") so that intellectual
            property owners could easily report listings that infringe their right to ensure that infringing products
            are removed from the site, as they erode Customer and good Seller trust.
          </li>
          <li>
            Only the intellectual property rights owner can report potentially infringing products or listings through
            Take Down Policy by way of Notice of infringement in the specified format. If you are not the intellectual
            property rights owner, you can still help by getting in touch with the rights owner and encouraging them to
            contact us.
          </li>
          <li>
            Note: MotoDoctor does not and cannot verify that Mechanic has the right or ability to sell or distribute
            their listed products or services. However, MotoDoctor is committed to ensure that item listings do not
            infringe upon intellectual property rights of third parties once an authorized representative of the rights
            owner properly reports them to MotoDoctor.
          </li>
        </ol>
        <p>
          <strong>CONTACT US</strong>
        </p>
        <p>
          Please contact us at&nbsp;<a href="mailto:support@motodoctor.in">support@motodoctor.in</a>&nbsp;for any
          questions or comments (including all inquiries unrelated to copyright infringement) regarding this Platform.
        </p>
        <p>Grievance Officer/Nodal Officer</p>
        <p>
          In accordance with (1) Information Technology Act, 2000 and rules made there under, and (2) Consumer
          Protection (E-Commerce) Rules 2020l the name and contact details of the Grievance Officer/Nodal Officer is
          provided below:
        </p>
        <p>MotoDoctor</p>
        <p>
          D no 354A,1st floor, MRP Complex, Thider Nagar, Cauvery Nagar, Kulathur(Taluk), Pudukkottai, Tamil Nadu, India
        </p>
        <p>
          Email:&nbsp;<a href="mailto:support@motodoctor.in">support@motodoctor.in</a>
        </p>
        <p>Time: Monday &ndash; Friday (9:00 am&ndash; 6:00 pm)</p>
      </div>
    </div>
  );
};

export default TermsCondition;
